// require("@rails/activestorage").start()
// require("channels")

// require("jquery")

require('./metronic/plugins.js');

// Keenthemes' plugins
window.KTUtil = require('./metronic/components/util.js');
window.KTApp = require('./metronic/components/app.js');
window.KTCard = require('./metronic/components/card.js');
window.KTCookie = require('./metronic/components/cookie.js');
window.KTDialog = require('./metronic/components/dialog.js');
window.KTHeader = require('./metronic/components/header.js');
// window.KTImageInput = require('./metronic/components/image-input.js');
window.KTMenu = require('./metronic/components/menu.js');
window.KTOffcanvas = require('./metronic/components/offcanvas.js');
// window.KTScrolltop = require('./metronic/components/scrolltop.js');
window.KTToggle = require('./metronic/components/toggle.js');
// window.KTWizard = require('./metronic/components/wizard.js');
// NOT USED

// Metronic layout base js
window.KTLayoutAside = require('./metronic/layout/base/aside.js');
window.KTLayoutAsideMenu = require('./metronic/layout/base/aside-menu.js');
window.KTLayoutAsideToggle = require('./metronic/layout/base/aside-toggle.js');
window.KTLayoutBrand = require('./metronic/layout/base/brand.js');
window.KTLayoutContent = require('./metronic/layout/base/content.js');
// window.KTLayoutFooter = require('./metronic/layout/base/footer.js');
window.KTLayoutHeader = require('./metronic/layout/base/header.js');
window.KTLayoutHeaderMenu = require('./metronic/layout/base/header-menu.js');
window.KTLayoutHeaderTopbar = require('./metronic/layout/base/header-topbar.js');
// window.KTLayoutStickyCard = require('./metronic/layout/base/sticky-card.js');
// window.KTLayoutStretchedCard = require('./metronic/layout/base/stretched-card.js');
// window.KTLayoutSubheader = require('./metronic/layout/base/subheader.js');

// Metronic layout extended js
// window.KTLayoutChat = require('./metronic/layout/extended/chat.js');
// window.KTLayoutDemoPanel = require('./metronic/layout/extended/demo-panel.js');
// window.KTLayoutExamples = require('./metronic/layout/extended/examples.js');
// window.KTLayoutQuickActions = require('./metronic/layout/extended/quick-actions.js');
// window.KTLayoutQuickCartPanel = require('./metronic/layout/extended/quick-cart.js');
// window.KTLayoutQuickNotifications = require('./metronic/layout/extended/quick-notifications.js');
// window.KTLayoutQuickPanel = require('./metronic/layout/extended/quick-panel.js');
// window.KTLayoutQuickSearch = require('./metronic/layout/extended/quick-search.js');
// window.KTLayoutQuickUser = require('./metronic/layout/extended/quick-user.js');
// window.KTLayoutScrolltop = require('./metronic/layout/extended/scrolltop.js');
// window.KTLayoutSearch = window.KTLayoutSearchOffcanvas = require('./metronic/layout/extended/search.js');

require('./metronic/layout/initialize.js');

require('./metronic/jquery.readall.min.js');

// require('./metronic/moment.tz.js');
// require('./metronic/moment-hu.js');

// require('moment.tz.js');
// require('moment-hu.js');

// require('./metronic/spin.min.js');
// require('./metronic/ladda.jquery.min.js');

// Save the mobiscroll 4 object before loading v5
window.mobiscroll4 = require('./metronic/mobiscroll4.jquery.min.js');
// Save the mobiscroll 4 plugin before loading v5
$.fn.mobiscroll4 = $.fn.mobiscroll;


window.mobiscroll = require('./metronic/mobiscroll5.jquery.min.js');

window.mobiscroll.localeDe = {
    setText: "OK",
    cancelText: "Abbrechen",
    clearText: "Löschen",
    selectedText: "{count} ausgewählt",
    dateFormat: "dd.mm.yy",
    dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    dayNamesMin: ["S", "M", "D", "M", "D", "F", "S"],
    dayText: "Tag",
    delimiter: ".",
    hourText: "Stunde",
    minuteText: "Minuten",
    monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    monthText: "Monat",
    secText: "Sekunden",
    timeFormat: "HH:ii",
    yearText: "Jahr",
    nowText: "Jetzt",
    pmText: "pm",
    amText: "am",
    todayText: "Heute",
    firstDay: 1,
    dateText: "Datum",
    timeText: "Zeit",
    closeText: "Schließen",
    allDayText: "Ganztägig",
    noEventsText: "Keine Ereignisse",
    eventText: "Ereignis",
    eventsText: "Ereignisse",
    moreEventsText: "{count} weiteres Element",
    moreEventsPluralText: "{count} weitere Elemente",
    fromText: "Von",
    toText: "Bis",
    wholeText: "Ganze Zahl",
    fractionText: "Bruchzahl",
    unitText: "Maßeinheit",
    // labels: ["Jahre", "Monate", "Tage", "Stunden", "Minuten", "Sekunden", ""],
    // labelsShort: ["Jahr.", "Mon.", "Tag.", "Std.", "Min.", "Sek.", ""],
    startText: "Starten",
    stopText: "Stoppen",
    resetText: "Zurücksetzen",
    lapText: "Lap",
    hideText: "Ausblenden",
    backText: "Zurück",
    undoText: "Rückgängig machen",
    offText: "Aus",
    onText: "Ein",
    decimalSeparator: ",",
    thousandsSeparator: " "
}

window.mobiscroll.localeEs = {
    setText: "Aceptar",
    cancelText: "Cancelar",
    clearText: "Borrar",
    selectedText: "{count} seleccionado",
    selectedPluralText: "{count} seleccionados",
    dateFormat: "dd/mm/yy",
    dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    dayNamesShort: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
    dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
    dayText: "Día",
    hourText: "Horas",
    minuteText: "Minutos",
    monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    monthText: "Mes",
    secText: "Segundos",
    timeFormat: "HH:ii",
    yearText: "A&ntilde;o",
    nowText: "Ahora",
    pmText: "pm",
    amText: "am",
    todayText: "Hoy",
    firstDay: 1,
    dateText: "Fecha",
    timeText: "Tiempo",
    closeText: "Cerrar",
    allDayText: "Todo el día",
    noEventsText: "No hay eventos",
    eventText: "Evento",
    eventsText: "Eventos",
    moreEventsText: "{count} más",
    fromText: "Iniciar",
    toText: "Final",
    wholeText: "Entero",
    fractionText: "Fracción",
    unitText: "Unidad",
    // labels: ["Años", "Meses", "Días", "Horas", "Minutos", "Segundos", ""],
    // labelsShort: ["Año", "Mes", "Día", "Hora", "Min", "Seg", ""],
    startText: "Iniciar",
    stopText: "Deténgase",
    resetText: "Reinicializar",
    lapText: "Lap",
    hideText: "Esconder",
    backText: "Atrás",
    undoText: "Deshacer",
    offText: "No",
    onText: "Sí",
    decimalSeparator: ",",
    thousandsSeparator: " "
}

window.mobiscroll.localeIt = {
    setText: "OK",
    cancelText: "Annulla",
    clearText: "Cancella",
    selectedText: "{count} selezionato",
    dateFormat: "dd.mm.yy",
    dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
    dayNamesShort: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
    dayNamesMin: ["D", "L", "M", "M", "G", "V", "S"],
    dayText: "Giorno",
    delimiter: ".",
    hourText: "Ora",
    minuteText: "Minuti",
    monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
    monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
    monthText: "Mese",
    secText: "Secondi",
    timeFormat: "HH:ii",
    yearText: "Anno",
    nowText: "Adesso",
    pmText: "pm",
    amText: "am",
    todayText: "Oggi",
    firstDay: 1,
    dateText: "Data",
    timeText: "Ora",
    closeText: "Chiudi",
    allDayText: "Tutto il giorno",
    noEventsText: "Nessun evento",
    eventText: "Evento",
    eventsText: "Eventi",
    moreEventsText: "{count} altro elemento",
    moreEventsPluralText: "{count} altri elementi",
    fromText: "Da",
    toText: "A",
    wholeText: "Numero intero",
    fractionText: "Frazione",
    unitText: "Unità di misura",
    // labels: ["Anni", "Mesi", "Giorni", "Ore", "Minuti", "Secondi", ""],
    // labelsShort: ["Anno.", "Mese.", "Giorno.", "Ora.", "Min.", "Sec.", ""],
    startText: "Inizia",
    stopText: "Ferma",
    resetText: "Ripristina",
    lapText: "Lap",
    hideText: "Nascondi",
    backText: "Indietro",
    undoText: "Annulla",
    offText: "Spento",
    onText: "Acceso",
    decimalSeparator: ",",
    thousandsSeparator: " "
}

window.MBOffcanvas = require('./metronic/mb_offcanvas.js');
require('./metronic/cropper.min.js');

;(function ($) {
    var oAddClass = $.fn.addClass;
    $.fn.addClass = function () {
        for (var i in arguments) {
            var arg = arguments[i];
            if (!!(arg && arg.constructor && arg.call && arg.apply)) {
                setTimeout(arg.bind(this));
                delete arguments[i];
            }
        }
        return oAddClass.apply(this, arguments);
    }

})(jQuery);

import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"

const bundle = Application.start()
const context = require.context("./muser_controllers", true, /\.js$/)
bundle.load(definitionsFromContext(context))

require("@rails/ujs").start()
require("turbolinks").start()
